<template>
  <div class="text-area-container">
    <textarea
      v-model="textAreaValue"
      v-on="updateValue()"
      :name="name"
      :id="id"
      :placeholder="placeholder"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "BaseTextArea",
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      textAreaValue: this.value,
    };
  },
  methods: {
    updateValue() {
      this.$emit("updatedValue", { id: this.id, value: this.textAreaValue });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.text-area-container {
  textarea {
    background-color: darken($lm-background-color, 4%);
    border: none;
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    resize: vertical;
    font-size: 1rem;
    box-sizing: border-box;
  }
}
</style>
