<template>
  <div class="container-loading-icon">
    <div class="content-loading-icon">
      <BaseBrandIcon />
    </div>
    <div class="text-loading-icon">Loading...<span class="dots-loading-icon"></span></div>
  </div>
</template>

<script>
import BaseBrandIcon from "@/components/BaseBrandIcon.vue";

export default {
  name: "BaseLoadingIcon",
  components: {
    BaseBrandIcon,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.container-loading-icon {
  padding: $standard-padding;
  .content-loading-icon {
    padding: 6px;
    background-color: $lm-brand-color;
    width: 50px !important;
    height: 50px !important;
    border-radius: $standard-radius;
    animation-name: spin;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out; 
  }
  .text-loading-icon {
    padding-top: $standard-padding;
    font-weight: bold;
  }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
</style>