<template>
  <div class="menuboek-category" v-if="type === 'category'">
    <BaseInput
      :value="name"
      :id="id"
      paddingSize="padding-none"
      @updatedValue="updatedValue"
    />
    <span class="icon">
      <font-awesome-icon
        v-on:click="deleteItem(id)"
        :icon="['fas', 'trash-alt']"
      />
    </span>
    <span class="icon">
      <font-awesome-icon
        v-on:click="toggleItemVisibility(id)"
        v-if="visible"
        :icon="['fas', 'eye']"
      />
      <font-awesome-icon
        v-on:click="toggleItemVisibility(id)"
        v-else
        :icon="['fas', 'eye-slash']"
      />
    </span>
    <span class="icon">
      <font-awesome-icon
        v-on:click="goTo(menuboekItem)"
        :icon="['fas', 'chevron-right']"
      />
    </span>
  </div>
  <div class="menuboek-subcategory" v-else-if="type === 'subcategory'">
    <BaseInput
      :value="name"
      :id="id"
      paddingSize="padding-none"
      @updatedValue="updatedValue"
    />
    <span class="icon">
      <font-awesome-icon
        v-on:click="openDescription"
        :icon="['fas', 'info-circle']"
      />
    </span>
    <span class="icon">
      <font-awesome-icon
        v-on:click="deleteItem(id)"
        :icon="['fas', 'trash-alt']"
      />
    </span>
    <span class="icon">
      <font-awesome-icon
        v-on:click="toggleItemVisibility(id)"
        v-if="visible"
        :icon="['fas', 'eye']"
      />
      <font-awesome-icon
        v-on:click="toggleItemVisibility(id)"
        v-else
        :icon="['fas', 'eye-slash']"
      />
    </span>
    <span class="icon">
      <font-awesome-icon
        v-on:click="goTo(menuboekItem)"
        :icon="['fas', 'chevron-right']"
      />
    </span>
  </div>
  <div class="menuboek-item" v-else-if="type === 'item'">
    <BaseInput
      :value="name"
      :id="id"
      paddingSize="padding-none"
      @updatedValue="updatedValue"
    />
    <BaseInput
      :value="String(price)"
      :id="id + '-price'"
      paddingSize="padding-none"
      @updatedValue="updatedValue"
    />
    <span class="icon">
      <font-awesome-icon
        v-on:click="openDescription"
        :icon="['fas', 'info-circle']"
      />
    </span>
    <span class="icon">
      <font-awesome-icon
        v-on:click="deleteItem(id)"
        :icon="['fas', 'trash-alt']"
      />
    </span>
    <span class="icon">
      <font-awesome-icon
        v-on:click="toggleItemVisibility(id)"
        v-if="visible"
        :icon="['fas', 'eye']"
      />
      <font-awesome-icon
        v-on:click="toggleItemVisibility(id)"
        v-else
        :icon="['fas', 'eye-slash']"
      />
    </span>
  </div>
  <div v-if="modalOpen" class="modal">
    <div class="modal-content">
      <div class="close-button">      
        <font-awesome-icon
        v-on:click="closeDescription"
        :icon="['fas', 'times']"
      />
      </div>
      <h2>{{ name }}</h2>
      <BaseTextArea
        :value="description"
        :id="id + '-description'"
        :name="id"
        placeholder="Beschrijving"
        @updatedValue="updatedValue"
      />
      <BaseButton @buttonPressed="closeDescription">Klaar</BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput.vue";
import BaseTextArea from "@/components/BaseTextArea";
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "BaseMenuboekItem",
  components: {
    BaseInput,
    BaseTextArea,
    BaseButton,
  },
  data() {
    return {
      modalOpen: false,
    };
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    price: {
      type: Number,
    },
    id: {
      type: String,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    description: {
      type: String,
      default: "",
    },
    menuboekItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      rangeOfMenuboekItems: "menuboekAdmin/findRangeOfMenuboekItems",
    }),
  },
  methods: {
    ...mapActions({
      changeMenuboekEditedItemValue:
        "menuboekAdmin/changeMenuboekEditedItemValue",
    }),
    ...mapMutations({
      deleteMenuboekItemsByRange: "menuboekAdmin/deleteMenuboekItemsByRange",
      moveMenuboekItemBehindOtherMenuboekItem:
        "menuboekAdmin/moveMenuboekItemBehindOtherMenuboekItem",
      toggleMenuboekItemVisibility:
        "menuboekAdmin/toggleMenuboekItemVisibility",
    }),
    goTo(menuboekItem) {
      this.$emit("goTo", menuboekItem);
    },
    deleteItem(id) {
      const confirm = window.confirm(
        "Ben je zeker dat je dit item wilt verwijderen!"
      );
      if (confirm) {
        this.deleteMenuboekItemsByRange(this.rangeOfMenuboekItems(id));
      }
    },
    updatedValue(payload) {
      this.changeMenuboekEditedItemValue(payload);
    },
    toggleItemVisibility(id) {
      this.toggleMenuboekItemVisibility(id);
    },
    openDescription() {
      this.modalOpen = true;
    },
    closeDescription() {
      this.modalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.menuboek-category {
  padding-bottom: $standard-padding;
  display: grid;
  grid-template-columns: auto 51px 51px 51px;
}

.menuboek-subcategory {
  padding-bottom: $standard-padding;
  display: grid;
  grid-template-columns: auto 51px 51px 51px 51px;
}

.menuboek-item {
  padding-bottom: $standard-padding;
  display: grid;
  grid-template-columns: auto 100px 51px 51px 51px;

  :nth-child(2) {
    padding-left: $standard-padding;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  transition: 0.2s color;
  svg {
    cursor: pointer;
  }
}

.icon:hover {
  color: darken($lm-text-color, 30%);
}

.modal {
  position: absolute; /* Stay in place */
  z-index: 3; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  /* Modal Content/Box */
  .modal-content {
    background-color: $lm-background-color;
    margin: 10% auto; /* 15% from the top and centered */
    padding: 20px;
    border-radius: $standard-radius;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    max-width: 600px;

    h2 {
      text-align: center;
      margin-bottom: $standard-padding;
    }

    .close-button {
      width: 51px;
      height: 30px;
            font-size: 1.6rem;
      float:right;
      display:flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .text-area-container {
      margin-bottom: $standard-margin;
    }

    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
