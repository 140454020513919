<template>
  <div id="category-list-container">
    <h1>{{ menuboekNaam }}</h1>
    <div id="category-list">
      <div v-for="category in categories" :key="category._id">
        <div
          class="drop"
          @drop="onDrop($event, category._id)"
          @dragover.prevent
          @dragenter.prevent
        >
          <div
            class="drag"
            draggable="true"
            @dragstart="startDrag($event, category)"
          >
            <BaseMenuboekItem
              :name="category.name"
              type="category"
              :id="category._id"
              :visible="category.visible"
              :menuboekItem="category"
              @goTo="goTo"
            />
          </div>
        </div>
      </div>
      <BaseButton @buttonPressed="insertEmptyMenuboekItem('')">
        Voeg categorie toe
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseMenuboekItem from "@/components/menuboekEditor/BaseMenuboekItem.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "MenuboekEditor",
  components: {
    BaseButton,
    BaseMenuboekItem,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    menuboekNaam: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      rangeOfMenuboekItems: "menuboekAdmin/findRangeOfMenuboekItems",
    }),
  },
  methods: {
    ...mapMutations({
      moveMenuboekItemBehindOtherMenuboekItem:
        "menuboekAdmin/moveMenuboekItemBehindOtherMenuboekItem",
    }),
    ...mapActions({
      insertEmptyMenuboekItem: "menuboekAdmin/insertEmptyMenuboekItem",
    }),
    startDrag: (evt, item) => {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item._id);
    },
    onDrop(evt, dropItemId) {
      this.moveMenuboekItemBehindOtherMenuboekItem({
        movingItemId: evt.dataTransfer.getData("itemID"),
        movingItemRangeOfChildren: this.rangeOfMenuboekItems(
          evt.dataTransfer.getData("itemID")
        ),
        destinationItemId: dropItemId,
        destinationItemRangeOfChildren: this.rangeOfMenuboekItems(dropItemId),
      });
    },
    goTo(menuboekItem) {
      this.$emit("goTo", menuboekItem);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
#category-list-container {
  padding-top: calc(#{$standard-padding} * 2);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  h1 {
    text-align: center;
    margin-bottom: calc(#{$standard-margin} * 2);
  }

  #category-list {
    overflow-y: scroll;
    box-sizing: border-box;
    padding-bottom: calc(#{$standard-padding} * 2);
    height: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
  #category-list::-webkit-scrollbar {
    display: none;
  }
}
</style>
