<template>
  <div id="sub-category-list-container">
    <div id="sub-category-header-bar">
      <span class="icon" v-on:click="goTo({})">
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
      </span>
      <h1>{{ menuboekNaam }}</h1>
      <h2>{{ parentItem.name }}</h2>
    </div>
    <div id="sub-category-list">
      <div v-for="subCategory in subCategories" :key="subCategory._id">
        <div
          class="drop"
          @drop="onDrop($event, subCategory._id)"
          @dragover.prevent
          @dragenter.prevent
        >
          <div
            class="drag"
            draggable="true"
            @dragstart="startDrag($event, subCategory)"
          >
            <BaseMenuboekItem
              :name="subCategory.name"
              type="subcategory"
              :id="subCategory._id"
              @goTo="goTo(subCategory)"
              :visible="subCategory.visible"
              :description="subCategory.description"
            />
          </div>
        </div>
      </div>
      <BaseButton @buttonPressed="insertEmptyMenuboekItem(parentItem._id)">
        Voeg item toe
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseMenuboekItem from "@/components/menuboekEditor/BaseMenuboekItem.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "MenuboekEditor",
  components: {
    BaseButton,
    BaseMenuboekItem,
  },
  props: {
    subCategories: {
      type: Array,
      required: true,
    },
    menuboekNaam: {
      type: String,
      required: true,
    },
    parentItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      rangeOfMenuboekItems: "menuboekAdmin/findRangeOfMenuboekItems",
    }),
  },
  methods: {
    ...mapMutations({
      moveMenuboekItemBehindOtherMenuboekItem:
        "menuboekAdmin/moveMenuboekItemBehindOtherMenuboekItem",
    }),
    ...mapActions({
      insertEmptyMenuboekItem: "menuboekAdmin/insertEmptyMenuboekItem",
    }),
    goTo(menuboekItem) {
      this.$emit("goTo", menuboekItem);
    },
    startDrag: (evt, item) => {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item._id);
    },
    onDrop(evt, dropItemId) {
      this.moveMenuboekItemBehindOtherMenuboekItem({
        movingItemId: evt.dataTransfer.getData("itemID"),
        movingItemRangeOfChildren: this.rangeOfMenuboekItems(
          evt.dataTransfer.getData("itemID")
        ),
        destinationItemId: dropItemId,
        destinationItemRangeOfChildren: this.rangeOfMenuboekItems(dropItemId),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
#sub-category-list-container {
  padding-top: calc(#{$standard-padding} * 2);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  #sub-category-header-bar {
    margin-bottom: calc(#{$standard-margin} * 2);
    span.icon {
      cursor: pointer;
      float: left;
      font-size: 1.4rem;
      transition: 0.2s color;
      :hover {
        color: darken($lm-text-color, 30%);
      }
    }
    h1,
    h2 {
      text-align: center;
      padding-left: calc(#{$standard-padding}* 2);
      padding-right: calc(#{$standard-padding} * 2);
    }
  }

  #sub-category-list {
    overflow-y: scroll;
    box-sizing: border-box;
    padding-bottom: calc(#{$standard-padding} * 2);
    height: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
  #sub-category-list::-webkit-scrollbar {
    display: none;
  }
}
</style>
