<template>
  <div class="menuboek-editor-container">
    <div class="menuboek-editor-side-bar">
      <BaseButton
        :disabled="editedEqualsOriginal || updating"
        @buttonPressed="updateMenuboek"
      >
        <span v-if="updating"> Updating... </span>
        <span v-else-if="editedEqualsOriginal || isFetchingMenuboek"
          >Up-to-date</span
        >
        <span v-else>Update</span>
      </BaseButton>
      <!--
      <BaseButton @buttonPressed="$router.push('/portal/menuboekPreview')">
        Bekijk preview
      </BaseButton>
      -->
    </div>
    <div class="menuboek-editor-content">
      <div class="loading-icon" v-if="isFetchingMenuboek">
        <BaseLoadingIcon/>
      </div>
      <div class="list" v-else-if="childRange.parentType === 'subtype'">
        <!-- items -->
        <TheItemList
          :items="items"
          :menuboekNaam="menuboekName"
          :parentItem="showListStartItem"
          :goToItem="goToItem"
          @goTo="changeShowListStartItem"
        />
      </div>

      <!-- Sub categorieen-->
      <div class="list" v-else-if="childRange.parentType === 'hoofdtype'">
        <TheSubCategoryList
          :subCategories="subCategories"
          :menuboekNaam="menuboekName"
          :parentItem="showListStartItem"
          @goTo="changeShowListStartItem"
        />
      </div>

      <!-- Hoofd categorieen -->
      <div class="list" v-else>
        <TheCategoryList
          :categories="categories"
          :menuboekNaam="menuboekName"
          @goTo="changeShowListStartItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseLoadingIcon from "@/components/BaseLoadingIcon.vue";
import TheCategoryList from "@/components/menuboekEditor/TheCategoryList.vue";
import TheSubCategoryList from "@/components/menuboekEditor/TheSubCategoryList.vue";
import TheItemList from "@/components/menuboekEditor/TheItemList.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "MenuboekEditor",
  components: {
    BaseButton,
    TheCategoryList,
    TheSubCategoryList,
    TheItemList,
    BaseLoadingIcon
  },
  data() {
    return {
      showListStartItem: {},
    };
  },
  computed: {
    ...mapState({
      menuboekEdited: (state) => state.menuboekAdmin.menuboekEdited,
      isFetchingMenuboek: (state) => state.menuboekAdmin.isFetchingMenuboek,
    }),
    ...mapGetters({
      editedEqualsOriginal: "menuboekAdmin/editedEqualsOriginalMenubook",
      rangeOfMenuboekItems: "menuboekAdmin/findRangeOfMenuboekItems",
      menuboekName: "menuboekAdmin/menuboekName",
      notifications: "notification/notifications",
    }),
    childRange() {
      return this.rangeOfMenuboekItems(this.showListStartItem._id);
    },
    categories() {
      return this.menuboekEdited.filter((item) => {
        return item.category === "hoofdtype";
      });
    },
    subCategories() {
      let addItem = false;
      let list = [];
      this.menuboekEdited.forEach((item) => {
        if (addItem) {
          if (item.category === "hoofdtype") {
            addItem = false;
          }
        }
        if (addItem) {
          list.push(item);
        }
        if (item._id === this.showListStartItem._id) {
          addItem = true;
        }
      });
      return list.filter((item) => {
        return item.category === "subtype";
      });
    },
    items() {
      let addItem = false;
      let list = [];
      this.menuboekEdited.forEach((item) => {
        if (addItem) {
          if (item.category !== "item") {
            addItem = false;
          }
        }
        if (addItem) {
          list.push(item);
        }
        if (item._id === this.showListStartItem._id) {
          addItem = true;
        }
      });
      return list;
    },
    goToItem() {
      if (this.showListStartItem.category === "subtype") {
        let goToItem = {};
        let foundStartItem = false;
        this.menuboekEdited.forEach((item) => {
          if (item._id === this.showListStartItem._id) {
            foundStartItem = true;
          }
          if (!foundStartItem && item.category === "hoofdtype") {
            goToItem = item;
          }
        });
        return goToItem;
      } else {
        return {};
      }
    },
  },
  async created() {
    await this.fetchMenuboek();
  },
  methods: {
    ...mapActions({
      fetchMenuboek: "menuboekAdmin/fetchMenuboek",
      updateMenuboek: "menuboekAdmin/updateMenuboek",
      insertEmptyMenuboekItem: "menuboekAdmin/insertEmptyMenuboekItem",
    }),
    ...mapMutations({
      moveMenuboekItemBehindOtherMenuboekItem:
        "menuboekAdmin/moveMenuboekItemBehindOtherMenuboekItem",
    }),
    changeShowListStartItem(menuboekItem) {
      this.showListStartItem = menuboekItem;
    },
    startDrag: (evt, item) => {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item._id);
    },
    onDrop(evt, dropItemId) {
      this.moveMenuboekItemBehindOtherMenuboekItem({
        movingItemId: evt.dataTransfer.getData("itemID"),
        movingItemRangeOfChildren: this.rangeOfMenuboekItems(
          evt.dataTransfer.getData("itemID")
        ),
        destinationItemId: dropItemId,
        destinationItemRangeOfChildren: this.rangeOfMenuboekItems(dropItemId),
      });
    },
    insertEmptyMenuboekItem(parentId) {
      this.insertEmptyMenuboekItemByParentId(parentId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.menuboek-editor-container {
  display: grid;
  grid-template-columns: 240px auto;
  overflow: hidden;
  height: 100%;

  .menuboek-editor-side-bar {
    border-right: 1px solid $lm-divider-color;
    margin-top: calc(#{$standard-margin}* 2);

    button {
      margin-bottom: $standard-margin;
    }
  }

  .menuboek-editor-content {
    margin-left: calc(#{$standard-margin}* 2);
    overflow: hidden;

    .list {
      height: 100%;
      overflow-y: scroll;
    }

    .loading-icon {
      height: 100%;
      display:flex;
      justify-content: center;
      align-items:center;
    }
  }
}

.icon {
  font-size: 1.4rem;
  color: $lm-text-color;
}
</style>
